import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Table, Badge, ProgressBar } from "react-bootstrap";

const tableData = [
  {
    id: "1",
    name: "Yatin Xarma",
    subject: "Programming",
    qualification: "B.Tech",
    fee: "117.00",
    status: "Good",
  },
  {
    id: "2",
    name: "Hanu Chang",
    subject: "Basic Algorithm",
    qualification: "B.E",
    fee: "215.50",
    status: "Good",
  },
  {
    id: "3",
    name: "Jordan Nico",
    subject: "English",
    qualification: "B.A",
    fee: "210.70",
    status: "Good",
  },
  {
    id: "4",
    name: "Nadila Adja",
    subject: "History",
    qualification: "B.A",
    fee: "204.50",
    status: "Bad",
  },
  {
    id: "5",
    name: "James Brown",
    subject: "Commarce",
    qualification: "B.Com",
    fee: "217.70",
    status: "Good",
  },
  {
    id: "6",
    name: "Jack John",
    subject: "Software Engg",
    qualification: "B.Tech",
    fee: "200.10",
    status: "Bad",
  },
  {
    id: "7",
    name: "Tony Soap",
    subject: "It Engg",
    qualification: "B.Tech",
    fee: "217.70",
    status: "Good",
  },
  {
    id: "8",
    name: "Yatin Xarma",
    subject: "Programming",
    qualification: "B.Tech",
    fee: "117.00",
    status: "Good",
  },
  {
    id: "9",
    name: "Hanu Chang",
    subject: "Basic Algorithm",
    qualification: "B.E",
    fee: "215.50",
    status: "Bad",
  },
  {
    id: "10",
    name: "Jordan Nico",
    subject: "English",
    qualification: "B.A",
    fee: "210.70",
    status: "Good",
  },
  {
    id: "11",
    name: "Nadila Adja",
    subject: "History",
    qualification: "B.A",
    fee: "204.50",
    status: "Bad",
  },
  {
    id: "12",
    name: "James Brown",
    subject: "Commarce",
    qualification: "B.Com",
    fee: "217.70",
    status: "Good",
  },
  {
    id: "13",
    name: "Jack John",
    subject: "Software Engg",
    qualification: "B.Tech",
    fee: "200.10",
    status: "Bad",
  },
  {
    id: "14",
    name: "Tony Soap",
    subject: "It Engg",
    qualification: "B.Tech",
    fee: "217.70",
    status: "Good",
  },
  {
    id: "15",
    name: "Jordan Nico",
    subject: "English",
    qualification: "B.A",
    fee: "210.70",
    status: "Bad",
  },
  {
    id: "16",
    name: "Kohni Pandye",
    subject: "Sanskrit",
    qualification: "B.Tech",
    fee: "150.50",
    status: "Good",
  },
];

export const TeacherDetails = ({ companies }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [company, setCompanies] = useState([]);

  const [searchString, setSearchString] = useState("");
  const [rowNumber, setRowNumber] = useState(1);
  const [allRecords, setAllRecords] = useState([]);

  useEffect(() => {
    // setCompanies([...companies].reverse());
    setAllRecords([...companies].reverse());
  }, []);

  // Filter Starts
  const [inputValue, setInputValue] = useState("");
  const matchesSearchText = (companyDetails) => {
    const searchText = inputValue.toLowerCase();
    return (
      String(companyDetails.first_name).toLowerCase().includes(searchText) ||
      String(companyDetails.last_name).toLowerCase().includes(searchText) ||
      String(companyDetails.email).toLowerCase().includes(searchText) ||
      String(companyDetails.city).toLowerCase().includes(searchText) ||
      String(companyDetails.phone).toLowerCase().includes(searchText)
    );
  };

  // Filter applied jobs based on the search text
  const filterRows = allRecords ? allRecords.filter(matchesSearchText) : [];

  const recordsPage = 10;
  const currentRecords = inputValue ? filterRows : allRecords; // Use filtered records if searching
  const npage = Math.ceil(currentRecords.length / recordsPage);
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;
  const records = currentRecords.slice(firstIndex, lastIndex); // Apply pagination on filtered or full records
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      setRowNumber(10 * (currentPage - 1) - 9);
    }
  }

  function changeCPage(id) {
    setRowNumber(10 * id - 9);
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
      setRowNumber(10 * (currentPage + 1) - 9);
    }
  }

  return (
    <div className="card" >
      <Col lg={12}>
        <Card>
          <Card.Header>
            <h3>New Companies</h3>
          </Card.Header>
          <Card.Body>
            <div className="col-sm-12">
              <div className="">
                <div className="input-group search-area mb-3 ">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here..."
                    value={inputValue}
                    // onChange={(e) => setSearchString(e.target.value)}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                  <span className="input-group-text">
                    <Link to={"#"}>
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z"
                          fill="#01A3FF"
                        />
                      </svg>
                    </Link>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sm-12 wow fadeInUp" data-wow-delay="1.5s"></div>
            <div className="table-responsive basic-tbl">
              <div
                id="teacher-table_wrapper"
                className="dataTables_wrapper no-footer"
              >
                <Table
                  responsive
                  id="example-student"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr
                      style={{
                        textAlign: "center",
                        background: "#212A50",
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    >
                      <th>ID</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>City</th>
                      <th style={{ textAlign: "center" }}>Phone</th>
                      {/* <th>Individuals</th> */}
                      {/* <th className="text-end">Performance</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {records &&
                      records.map((item, idx) => (
                        <tr style={{ textAlign: "center" }} key={++idx}>
                          <td>{++idx}</td>
                          <td>{item.first_name + " " + item.last_name}</td>
                          <td>{item.email}</td>
                          <td>{item.city}</td>
                          <td style={{ textAlign: "center" }}>{item.phone}</td>
                          {/* <td>${item.fee}</td>
                                <td>${item.fee}</td>
                                <td>${item.fee}</td> */}
                          {/* <td className="text-end"><span className={`badge badge-sm light badge-${item.status === "Good" ?  'success' : 'danger' }`}>{item.status}</span></td> */}
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <div className="d-sm-flex text-center justify-content-between align-items-center">
                  <div className="dataTables_info">
                    Showing {lastIndex - recordsPage + 1} to{" "}
                    {company?.length < lastIndex ? company?.length : lastIndex}{" "}
                    Companies
                    {/* {company?.length} entries */}
                  </div>
                  <div
                    className="dataTables_paginate paging_simple_numbers justify-content-center"
                    id="example2_paginate"
                  >
                    <Link
                      className="paginate_button previous disabled"
                      to="#"
                      onClick={prePage}
                    >
                      <i className="fa-solid fa-angle-left" />
                    </Link>
                    <span>
                      {number.map((n, i) => (
                        <Link
                          className={`paginate_button ${
                            currentPage === n ? "current" : ""
                          } `}
                          key={i}
                          onClick={() => changeCPage(n)}
                        >
                          {n}
                        </Link>
                      ))}
                    </span>
                    <Link
                      className="paginate_button next"
                      to="#"
                      onClick={nextPage}
                    >
                      <i className="fa-solid fa-angle-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};
