import React from "react";
import { Link } from "react-router-dom";
import { Nav, Tab, Dropdown } from "react-bootstrap";
import { IMAGES, SVGICON } from "./Dashboard/Content";
import { FaEye } from "react-icons/fa";
import { Button, ButtonGroup } from "react-bootstrap";
import { FaTrashRestore } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { FaDeleteLeft } from "react-icons/fa6";
import Tabs from "react-bootstrap/Tabs";
import { ImCross } from "react-icons/im";
import { FaDownload } from "react-icons/fa";
import { BiSolidEdit } from "react-icons/bi";
import { RiChatDeleteFill } from "react-icons/ri";
import "../scss/care.css";

import PageTitle from "../layouts/PageTitle";
import { Row, Col, Card, Badge, ProgressBar } from "react-bootstrap";
import fetchData from "../../axios";
import { useEffect } from "react";
import { useState } from "react";
import { date } from "yup";

const svg1 = (
  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
    </g>
  </svg>
);

const SalesTab = () => {
  const [reports, setReports] = useState([]);
  const [groupByDay, setGroupByDay] = useState([]);
  const [filteredGroupByDay, setFilteredGroupByDay] = useState([]);
  const [daily, setDaily] = useState([]);
  const [years, setYears] = useState([]);
  const [groupByYear, setGroupByYear] = useState([]);
  const [filteredGroupByYear, setFilteredGroupByYear] = useState([]);
  const [type, setType] = useState("day");

  var monthNames = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    12: "November",
    12: "December",
  };
  const makeRequest = fetchData();
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  function removeDup(arr) {
    return arr.filter((value, index, self) => self.indexOf(value) === index);
  }
  useEffect(() => {
    makeRequest("GET", "/course/get-all-purchased-course-group-by")
      .then((res) => {
        setDaily(res.data.response.dailyReport.reverse()); //Daily
        setFilteredGroupByDay(res.data.response.groupByDay.reverse()); //Monthly
        setGroupByDay(res.data.response.groupByDay);
        setFilteredGroupByYear(res.data.response.groupByYear.reverse()); //Yearly
        let year = res.data.response.groupByYear.map((item) => {
          return item.year;
        });
        setYears(removeDup(year));
        setGroupByYear(res.data.response.groupByYear);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);

  useEffect(() => {
    setFilteredGroupByDay(() => {
      if (month != "" && year != "") {
        return groupByDay.filter((data) => {
          if (data.month == month && data.year == year) {
            return data;
          }
        });
      } else if (month != "") {
        return groupByDay.filter((data) => data.month == month);
      } else if (year != "") {
        return groupByDay.filter((data) => data.year == year);
      } else {
        return groupByDay;
      }
    });
  }, [month, year]);

  // Filter Starts
  const [tabValue, setTabValue] = useState("daily");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [rowNumber, setRowNumber] = useState(1);
  const [allRecords, setAllRecords] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const matchesSearchText = (salesDetails) => {
    const searchText = inputValue.toLowerCase();
    if (tabValue == "daily") {
      return (
        String(salesDetails?.first_name).toLowerCase().includes(searchText) ||
        String(salesDetails?.last_name).toLowerCase().includes(searchText) ||
        String(salesDetails?.fake_course_count)
          .toLowerCase()
          .includes(searchText) ||
        String(salesDetails?.time).toLowerCase().includes(searchText) ||
        String(salesDetails?.date).toLowerCase().includes(searchText) ||
        parseFloat(salesDetails?.amount)
          ?.toFixed(2)
          .toLowerCase()
          .includes(searchText)
      );
    } else if (tabValue == "monthly") {
      return (
        String(salesDetails?.total_course_count)
          .toLowerCase()
          .includes(searchText) ||
        String(
          salesDetails.day + "/" + salesDetails.month + "/" + salesDetails.year
        )
          .toLowerCase()
          .includes(searchText) ||
        parseFloat(salesDetails?.total_amount)
          ?.toFixed(2)
          .toLowerCase()
          .includes(searchText)
      );
    } else if (tabValue == "yearly") {
      return (
        String(salesDetails?.total_course_count)
          .toLowerCase()
          .includes(searchText) ||
        String(monthNames[salesDetails?.month])
          .toLowerCase()
          .includes(searchText) ||
        String(salesDetails?.year).toLowerCase().includes(searchText) ||
        parseFloat(salesDetails?.total_amount)
          ?.toFixed(2)
          .toLowerCase()
          .includes(searchText)
      );
    }
  };

  // Filter applied jobs based on the search text
  const filterRows =
    tabValue == "daily"
      ? daily
        ? daily.filter(matchesSearchText)
        : []
      : tabValue == "monthly"
      ? filteredGroupByDay
        ? filteredGroupByDay.filter(matchesSearchText)
        : []
      : tabValue == "yearly"
      ? filteredGroupByYear
        ? filteredGroupByYear.filter(matchesSearchText)
        : []
      : [];

  const recordsPage = 10;
  const currentRecords = inputValue
    ? filterRows
    : tabValue == "daily"
    ? daily
    : tabValue == "monthly"
    ? filteredGroupByDay
    : tabValue == "yearly"
    ? filteredGroupByYear
    : []; // Use filtered records if searching
  const npage = Math.ceil(currentRecords.length / recordsPage);
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;
  const records = currentRecords.slice(firstIndex, lastIndex); // Apply pagination on filtered or full records
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      setRowNumber(10 * (currentPage - 1) - 9);
    }
  }

  function changeCPage(id) {
    setRowNumber(10 * id - 9);
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
      setRowNumber(10 * (currentPage + 1) - 9);
    }
  }

  const customStyles = {
    tabsContainer: {
      margin: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "1px solid grey",
      borderRadius: "20px",
    },
    tabLink: {
      backgroundColor: "#212450",
      color: "white",
      // fontSize: "16px",
      fontWeight: "bold",
      padding: "10px",
      borderRadius: "15px",
      margin: "0px",
      textAlign: "center",
      textTransform: "uppercase",
      transition: "background-color 0.3s ease",
      cursor: "pointer",
    },
    tabLinkActive: {
      backgroundColor: "#4CAF50",
      color: "white",
      // fontSize: "18px",
      borderRadius: "20px",
    },
    tabLinkHover: {
      backgroundColor: "#555",
      color: "white",
    },
  };

  return (
    <div className="card border border-grey" style={{borderRadius:"20px"}}>
      <Col xl={12}>
        <Tabs
          defaultActiveKey={tabValue}
          id="fill-tab-example"
          className="mb-3 mt-3 m-2 border border-grey p-3"
          fill
          style={{ color: "white", borderRadius: "20px" }}
          onSelect={(key) => {
            setTabValue(key);
            setInputValue("");
          }}
        >
          {/* {tabValue} */}
          <Tab
            eventKey="daily"
            // title="Daily Sales"
            title={
              <span
                style={{
                  ...customStyles.tabLink,
                  ...(tabValue === "daily" && customStyles.tabLinkActive),
                }}
              >
                Daily Sales
              </span>
            }
          >
            <Col lg={12}>
              <Card>
                <Card.Header className="pt-1 pb-2">
                  <h3>Daily Sales</h3>
                </Card.Header>

                <Card.Body>
                  <div className="col-sm-12">
                    <div className="">
                      <div className="input-group search-area mb-3 ">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search here..."
                          value={inputValue}
                          // onChange={(e) => setSearchString(e.target.value)}
                          onChange={(e) => setInputValue(e.target.value)}
                        />
                        <span className="input-group-text">
                          <Link to={"#"}>
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z"
                                fill="#01A3FF"
                              />
                            </svg>
                          </Link>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 wow fadeInUp" data-wow-delay="1.5s">
                    <div className="table-responsive full-data">
                      <div
                        id="example-student_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <Table responsive>
                          <thead>
                            <tr
                              style={{ background: "#212a50", color: "#fff" }}
                            >
                              <th style={{ color: "#fff" }} className="width80">
                                <strong>SL</strong>
                              </th>
                              <th
                                style={{ textAlign: "center", color: "#fff" }}
                              >
                                <strong>User</strong>
                              </th>
                              <th
                                style={{ textAlign: "center", color: "#fff" }}
                              >
                                <strong>Course Count</strong>
                              </th>
                              <th
                                style={{ textAlign: "center", color: "#fff" }}
                              >
                                <strong>Time</strong>
                              </th>
                              <th
                                style={{ textAlign: "center", color: "#fff" }}
                              >
                                <strong>Date</strong>
                              </th>
                              <th
                                style={{ textAlign: "center", color: "#fff" }}
                              >
                                <strong>Amount</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {records &&
                              records.map((item, i) => {
                                return (
                                  <tr>
                                    <td>
                                      <strong>{rowNumber + i}</strong>
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {item.first_name + " " + item.last_name}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {item.fake_course_count <= 0
                                        ? 1
                                        : item.fake_course_count}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {item.time}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {item.date}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      £ {parseFloat(item.amount).toFixed(2)}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                        {/* Pagination Start */}
                        <div className="d-sm-flex text-center justify-content-end align-items-center">
                          <div className="dataTables_paginate paging_simple_numbers justify-content-center">
                            <Link
                              className="paginate_button previous disabled"
                              to="#"
                              onClick={prePage}
                            >
                              <i className="fa-solid fa-angle-left" />
                            </Link>
                            <span>
                              {number.map((n, i) => (
                                <Link
                                  className={`paginate_button ${
                                    currentPage === n ? "current" : ""
                                  }`}
                                  key={i}
                                  onClick={() => changeCPage(n)}
                                >
                                  {n}
                                </Link>
                              ))}
                            </span>
                            <Link
                              className="paginate_button next"
                              to="#"
                              onClick={nextPage}
                            >
                              <i className="fa-solid fa-angle-right" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Pagination End */}
                </Card.Body>
              </Card>
            </Col>
          </Tab>

          <Tab
            eventKey="monthly"
            // title="Monthly"
            title={
              <span
                style={{
                  ...customStyles.tabLink,
                  ...(tabValue === "monthly" && customStyles.tabLinkActive),
                }}
              >
                Monthly Sales
              </span>
            }
          >
            <Col lg={12}>
              <Card>
                <Card.Header className="pt-1 pb-2">
                  <h3>Monthly Sales</h3>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "end",
                      marginRight: "2.4rem",
                    }}
                    className=""
                  >
                    <div className=" mb-md-0 mb-3 form-group ">
                      <select
                        style={{
                          backgroundColor: "#5a9676",
                          color: "white",
                          textAlign: "left",
                        }}
                        onChange={(e) => setMonth(e.target.value)}
                        className="form-control mr-2"
                        aria-label="Default select example"
                      >
                        <option value="">Select Month </option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">Sepetember</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </select>
                    </div>
                    <div className="">
                      <select
                        style={{
                          backgroundColor: "#5a9676",
                          color: "white",
                          textAlign: "left",
                          marginLeft: ".2rem",
                        }}
                        onChange={(e) => setYear(e.target.value)}
                        className="form-control"
                        aria-label="Default select example"
                      >
                        <option value="">Select Year</option>
                        {years &&
                          years.map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="col-sm-12">
                    <div className="">
                      <div className="input-group search-area mb-3 ">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search here..."
                          value={inputValue}
                          // onChange={(e) => setSearchString(e.target.value)}
                          onChange={(e) => setInputValue(e.target.value)}
                        />
                        <span className="input-group-text">
                          <Link to={"#"}>
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z"
                                fill="#01A3FF"
                              />
                            </svg>
                          </Link>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 wow fadeInUp" data-wow-delay="1.5s">
                    <div className="table-responsive full-data">
                      <div
                        id="example-student_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <Table responsive>
                          <thead>
                            <tr
                              style={{ color: "#fff", background: "#212a50" }}
                            >
                              <th className="width80" style={{ color: "#fff" }}>
                                <strong>SL No</strong>
                              </th>
                              <th
                                style={{ textAlign: "center", color: "#fff" }}
                              >
                                <strong>Course Count</strong>
                              </th>
                              <th
                                style={{ textAlign: "center", color: "#fff" }}
                              >
                                <strong>Date</strong>
                              </th>
                              <th
                                style={{ textAlign: "center", color: "#fff" }}
                              >
                                <strong>Amount</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {records &&
                              records.map((item, i) => {
                                return (
                                  <tr>
                                    <td>
                                      <strong>{rowNumber + i}</strong>
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {item.total_course_count <= 0
                                        ? 1
                                        : item.total_course_count}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {item.day +
                                        "/" +
                                        item.month +
                                        "/" +
                                        item.year}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      £{" "}
                                      {parseFloat(item.total_amount).toFixed(2)}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                        {/* Pagination Start */}
                        <div className="d-sm-flex text-center justify-content-end align-items-center">
                          <div className="dataTables_paginate paging_simple_numbers justify-content-center">
                            <Link
                              className="paginate_button previous disabled"
                              to="#"
                              onClick={prePage}
                            >
                              <i className="fa-solid fa-angle-left" />
                            </Link>
                            <span>
                              {number.map((n, i) => (
                                <Link
                                  className={`paginate_button ${
                                    currentPage === n ? "current" : ""
                                  }`}
                                  key={i}
                                  onClick={() => changeCPage(n)}
                                >
                                  {n}
                                </Link>
                              ))}
                            </span>
                            <Link
                              className="paginate_button next"
                              to="#"
                              onClick={nextPage}
                            >
                              <i className="fa-solid fa-angle-right" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Pagination End */}
                </Card.Body>
              </Card>
            </Col>
          </Tab>

          <Tab
            eventKey="yearly"
            // title="Yearly"
            title={
              <span
                style={{
                  ...customStyles.tabLink,
                  ...(tabValue === "yearly" && customStyles.tabLinkActive),
                }}
              >
                Yearly Sales
              </span>
            }
          >
            <Col lg={12}>
              <Card>
                <Card.Header className="pt-1 pb-2">
                  <h3>Yearly Sales</h3>

                  <div>
                    {" "}
                    <div className="form-group  mb-md-0 mb-3">
                      <select
                        style={{ backgroundColor: "#5a9676", color: "white" }}
                        onChange={(e) =>
                          setFilteredGroupByYear(
                            e.target.value
                              ? groupByYear.filter(
                                  (data) => data.year == e.target.value
                                )
                              : groupByYear
                          )
                        }
                        className="form-control"
                        aria-label="Default select example"
                      >
                        <option value="">Select Year</option>
                        {years &&
                          years.map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="col-sm-12">
                    <div className="">
                      <div className="input-group search-area mb-3 ">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search here..."
                          value={inputValue}
                          // onChange={(e) => setSearchString(e.target.value)}
                          onChange={(e) => setInputValue(e.target.value)}
                        />
                        <span className="input-group-text">
                          <Link to={"#"}>
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z"
                                fill="#01A3FF"
                              />
                            </svg>
                          </Link>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 wow fadeInUp" data-wow-delay="1.5s">
                    <div className="table-responsive full-data">
                      <div
                        id="example-student_wrapper"
                        className="dataTables_wrapper no-footer"
                      >
                        <Table responsive>
                          <thead>
                            <tr style={{ background: "#212a50" }}>
                              <th className="width80">
                                <strong>Sl No</strong>
                              </th>
                              <th style={{ textAlign: "center" }}>
                                <strong>Course Count</strong>
                              </th>
                              <th style={{ textAlign: "center" }}>
                                <strong>Month</strong>
                              </th>
                              <th style={{ textAlign: "center" }}>
                                <strong>Year</strong>
                              </th>
                              <th style={{ textAlign: "center" }}>
                                <strong>Amount</strong>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {records &&
                              records.map((item, i) => {
                                return (
                                  <tr>
                                    <td>
                                      <strong>{rowNumber + i}</strong>
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {item.total_course_count <= 0
                                        ? 1
                                        : item.total_course_count}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {monthNames[item.month]}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      {item.year}
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      £{" "}
                                      {parseFloat(item.total_amount).toFixed(2)}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                        {/* Pagination Start */}
                        <div className="d-sm-flex text-center justify-content-end align-items-center">
                          <div className="dataTables_paginate paging_simple_numbers justify-content-center">
                            <Link
                              className="paginate_button previous disabled"
                              to="#"
                              onClick={prePage}
                            >
                              <i className="fa-solid fa-angle-left" />
                            </Link>
                            <span>
                              {number.map((n, i) => (
                                <Link
                                  className={`paginate_button ${
                                    currentPage === n ? "current" : ""
                                  }`}
                                  key={i}
                                  onClick={() => changeCPage(n)}
                                >
                                  {n}
                                </Link>
                              ))}
                            </span>
                            <Link
                              className="paginate_button next"
                              to="#"
                              onClick={nextPage}
                            >
                              <i className="fa-solid fa-angle-right" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Pagination End */}
                </Card.Body>
              </Card>
            </Col>
          </Tab>
        </Tabs>
      </Col>
    </div>
  );
};

export default SalesTab;
