import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Nav, Tab, Dropdown } from "react-bootstrap";
import { IMAGES, SVGICON } from "./Dashboard/Content";
import { Button, ButtonGroup } from "react-bootstrap";
import { RiChatDeleteFill } from "react-icons/ri";
import PageTitle from "../layouts/PageTitle";
import { Row, Col, Card, Table, Badge, ProgressBar } from "react-bootstrap";
import fetchData from "../../axios";
import swal from "sweetalert";

const svg1 = (
  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
    </g>
  </svg>
);

const tabledata = [
  {
    image: IMAGES.food1,
    title: "Beef Steak with Fried Potato",
    subtitle: "Dinner",
    rating: "5.0",
    sales: "1,210",
    intrest: "20%",
  },
  {
    image: IMAGES.food2,
    title: "Pancake with Honey",
    subtitle: "Breakfast",
    rating: "4.9",
    sales: "1,110",
    intrest: "13&",
  },
  {
    image: IMAGES.food3,
    title: "Japanese Beef Ramen",
    subtitle: "Lunch",
    rating: "4.8",
    sales: "1,050",
    intrest: "18%",
  },
  {
    image: IMAGES.food4,
    title: "Mixed Salad",
    subtitle: "Lunch",
    rating: "5.0",
    sales: "1,400",
    intrest: "17%",
  },
  {
    image: IMAGES.food5,
    title: "Snack Beef Meatball with Vegetable",
    subtitle: "Snack",
    rating: "4.8",
    sales: "1,456",
    intrest: "15%",
  },
];
const tabledata2 = [
  {
    image: IMAGES.food5,
    title: "Beef Steak with Fried Potato",
    subtitle: "Breakfast",
    rating: "5.0",
    sales: "1,210",
    intrest: "20%",
  },
  {
    image: IMAGES.food2,
    title: "Pancake with Honey",
    subtitle: "Breakfast",
    rating: "4.9",
    sales: "1,110",
    intrest: "13&",
  },
  {
    image: IMAGES.food3,
    title: "Japanese Beef Ramen",
    subtitle: "Breakfast",
    rating: "4.8",
    sales: "1,050",
    intrest: "18%",
  },
];
const tabledata3 = [
  {
    image: IMAGES.food2,
    title: "Beef Steak with Fried Potato",
    subtitle: "Lunch",
    rating: "5.0",
    sales: "1,210",
    intrest: "20%",
  },
  {
    image: IMAGES.food2,
    title: "Pancake with Honey",
    subtitle: "Lunch",
    rating: "4.9",
    sales: "1,110",
    intrest: "13&",
  },
  {
    image: IMAGES.food3,
    title: "Japanese Beef Ramen",
    subtitle: "Lunch",
    rating: "4.8",
    sales: "1,050",
    intrest: "18%",
  },
  {
    image: IMAGES.food4,
    title: "Mixed Salad",
    subtitle: "Lunch",
    rating: "5.0",
    sales: "1,400",
    intrest: "17%",
  },
];
const tabledata4 = [
  {
    image: IMAGES.food3,
    title: "Mixed Salad",
    subtitle: "Snack",
    rating: "5.0",
    sales: "1,400",
    intrest: "17%",
  },
  {
    image: IMAGES.food5,
    title: "Snack Beef Meatball with Vegetable",
    subtitle: "Snack",
    rating: "4.8",
    sales: "1,456",
    intrest: "15%",
  },
];

const ViewExam = () => {
  const makeRequest = fetchData();
  const [exams, setExams] = useState([]);
  const [rowNumber, setRowNumber] = useState(1);
  const [allRecords, setAllRecords] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    makeRequest("GET", "/exam/get-all-exam")
      .then((res) => {
        setExams(res.data.response);
        setAllRecords(res.data.response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function deleteExam(id) {
    makeRequest("DELETE", `/exam/delete-exam/${id}`)
      .then((res) => {
        setExams(exams.filter((item) => item.id != id));
        swal("Done!", "Bundle Deleted", "success");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Filter Starts
  const [inputValue, setInputValue] = useState("");
  const matchesSearchText = (examDetails) => {
    const searchText = inputValue.toLowerCase();
    return (
      String(examDetails?.course_name)?.toLowerCase().includes(searchText) ||
      String(examDetails?.course_category)
        ?.toLowerCase()
        .includes(searchText) ||
      String(examDetails?.course_description)
        ?.toLowerCase()
        .includes(searchText) ||
      String(examDetails?.exam_id)?.toLowerCase().includes(searchText)
    );
  };

  // Filter applied jobs based on the search text
  const filterRows = allRecords ? allRecords.filter(matchesSearchText) : [];

  const recordsPage = 10;
  const currentRecords = inputValue ? filterRows : allRecords; // Use filtered records if searching
  const npage = Math.ceil(currentRecords.length / recordsPage);
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;
  const records = currentRecords.slice(firstIndex, lastIndex); // Apply pagination on filtered or full records
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      setRowNumber(10 * (currentPage - 1) - 9);
    }
  }

  function changeCPage(id) {
    setRowNumber(10 * id - 9);
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
      setRowNumber(10 * (currentPage + 1) - 9);
    }
  }

  return (
    <div className="card">
      <Col lg={12}>
        <Card>
          <Card.Header>
            <h3>All Exams</h3>
          </Card.Header>
          <Card.Body>
            <div className="col-sm-12">
              <div className="">
                <div className="input-group search-area mb-3 ">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here..."
                    value={inputValue}
                    // onChange={(e) => setSearchString(e.target.value)}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                  <span className="input-group-text">
                    <Link to={"#"}>
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z"
                          fill="#01A3FF"
                        />
                      </svg>
                    </Link>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sm-12 wow fadeInUp" data-wow-delay="1.5s">
              <div className="table-responsive full-data">
                <div
                  id="example-student_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <Table responsive>
                    <thead>
                      <tr
                        style={{ background: "#212A50", textAlign: "center" }}
                      >
                        <th className="width80">
                          <strong>Sl.No</strong>
                        </th>
                        <th>
                          <strong>Course Name</strong>
                        </th>
                        <th>
                          <strong>Category</strong>
                        </th>
                        <th>
                          <strong>Description</strong>
                        </th>
                        <th>
                          <strong>Exam ID</strong>
                        </th>
                        <th>
                          <strong>Category</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {records &&
                        records.map((item, i) => (
                          <tr style={{ textAlign: "center" }}>
                            <td>
                              <strong>{rowNumber + i}</strong>
                            </td>
                            <td>{item?.course_name}</td>
                            <td>{item?.course_category}</td>
                            <td>{item?.course_description.slice(0, 30)}</td>
                            <td>{item?.exam_id}</td>
                            <td>
                              <Button
                                title="Delete"
                                className="me-2"
                                variant="danger btn-icon-xxs"
                                onClick={() => deleteExam(item.exam_id)}
                              >
                                <RiChatDeleteFill />
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  {/* Pagination Start */}
                  <div className="d-sm-flex text-center justify-content-end align-items-center">
                    <div className="dataTables_paginate paging_simple_numbers justify-content-center">
                      <Link
                        className="paginate_button previous disabled"
                        to="#"
                        onClick={prePage}
                      >
                        <i className="fa-solid fa-angle-left" />
                      </Link>
                      <span>
                        {number.map((n, i) => (
                          <Link
                            className={`paginate_button ${
                              currentPage === n ? "current" : ""
                            }`}
                            key={i}
                            onClick={() => changeCPage(n)}
                          >
                            {n}
                          </Link>
                        ))}
                      </span>
                      <Link
                        className="paginate_button next"
                        to="#"
                        onClick={nextPage}
                      >
                        <i className="fa-solid fa-angle-right" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Pagination End */}
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};

export default ViewExam;
