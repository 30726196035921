import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import fetchData from "../../../../axios";

const SchoolPerformance = () => {
  const [series, setSeries] = useState([
    {
      name: "This Week",
      data: [],
    },
    {
      name: "Last Week",
      data: [],
    },
    {
      name: "Last to Last Week",
      data: [],
    },
  ]);

  const makeRequest = fetchData();

  useEffect(() => {
    // Helper function to get the start of the week (Monday)
    const getStartOfWeek = (date) => {
      const day = date.getDay();
      const diff = date.getDate() - day + (day === 0 ? -6 : 1); // Adjust when day is Sunday
      return new Date(date.setDate(diff));
    };

    const today = new Date();
    const startOfCurrentWeek = getStartOfWeek(new Date(today)); // Start of this week
    const startOfLastWeek = getStartOfWeek(new Date(today.setDate(today.getDate() - 7))); // Start of last week
    const startOfLastToLastWeek = getStartOfWeek(new Date(today.setDate(today.getDate() - 7))); // Start of last to last week

    makeRequest("GET", "/info/super-admin-dashboard-data")
      .then((res) => {
        const course = res.data.response.purchasedCourse || [];

        let currentWeekData = Array(7).fill(0);
        let lastWeekData = Array(7).fill(0);
        let lastToLastWeekData = Array(7).fill(0);

        course.forEach((item) => {
          const date = new Date(item.date);
          const dayIndex = (date.getDay() + 6) % 7; // Adjust to start the week from Monday
          const amount = Number(item.amount);

          if (date >= startOfCurrentWeek && date < new Date(startOfCurrentWeek.getTime() + 7 * 24 * 60 * 60 * 1000)) {
            currentWeekData[dayIndex] += amount;
          } else if (date >= startOfLastWeek && date < new Date(startOfLastWeek.getTime() + 7 * 24 * 60 * 60 * 1000)) {
            lastWeekData[dayIndex] += amount;
          } else if (date >= startOfLastToLastWeek && date < new Date(startOfLastToLastWeek.getTime() + 7 * 24 * 60 * 60 * 1000)) {
            lastToLastWeekData[dayIndex] += amount;
          }
        });

        setSeries([
          { name: "This Week", data: currentWeekData },
          { name: "Last Week", data: lastWeekData },
          { name: "Last To Last Week", data: lastToLastWeekData },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });

    // Hide the Clickable Buttons - Start
    const style = document.createElement("style");
    if (style) {
      style.type = "text/css";
      style.innerHTML = ".p-static { display: none; }";
      document.head.appendChild(style);
      // Clean up function to remove the style tag when the component unmounts
      return () => {
        document.head.removeChild(style);
      };
    }
    // Hide the Clickable Buttons - End
  }, []);

  const options = {
    chart: {
      type: "area",
      height: 280,
      toolbar: {
        show: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#4beb46", "#ff8027", "#8e6eff"],
    stroke: {
      curve: "smooth",
      width: 3,
      colors: ["#4beb46", "#ff8027", "#8e6eff"],
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "center",
    },
    grid: {
      show: false,
      strokeDashArray: 7,
      borderColor: "#dadada",
    },
    yaxis: {
      labels: {
        style: {
          colors: "#B5B5C3",
          fontSize: "12px",
          fontFamily: "Poppins",
          fontWeight: 400,
        },
        formatter: function (value) {
          return value ? `£${value.toLocaleString()}` : "£0";
        },
      },
    },
    xaxis: {
      categories: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
      labels: {
        style: {
          colors: "#B5B5C3",
          fontSize: "12px",
          fontFamily: "Poppins",
          fontWeight: 400,
        },
      },
    },
    fill: {
      type: "solid",
      opacity: 0.05,
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  return (
    <div id="marketChart">
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={280}
      />
    </div>
  );
};

export default SchoolPerformance;
