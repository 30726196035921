import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Nav, Tab, Dropdown } from "react-bootstrap";
import { IMAGES, SVGICON } from "../Dashboard/Content";
import circle from "./../../../images/circle.svg";
import PageTitle from "../../layouts/PageTitle";
import { Row, Col, Card, Table, Badge, ProgressBar } from "react-bootstrap";
import fetchData from "../../../axios";
import { MdDelete } from "react-icons/md";
import swal from "sweetalert";
import { RiChatDeleteFill } from "react-icons/ri";
import { Button, ButtonGroup } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { MdPublish } from "react-icons/md";
import { FaTrashRestore } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { FaDownload } from "react-icons/fa";

const svg1 = (
  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
    </g>
  </svg>
);

const tabledata = [
  {
    image: IMAGES.food1,
    title: "Beef Steak with Fried Potato",
    subtitle: "Dinner",
    rating: "5.0",
    sales: "1,210",
    intrest: "20%",
  },
  {
    image: IMAGES.food2,
    title: "Pancake with Honey",
    subtitle: "Breakfast",
    rating: "4.9",
    sales: "1,110",
    intrest: "13&",
  },
  {
    image: IMAGES.food3,
    title: "Japanese Beef Ramen",
    subtitle: "Lunch",
    rating: "4.8",
    sales: "1,050",
    intrest: "18%",
  },
  {
    image: IMAGES.food4,
    title: "Mixed Salad",
    subtitle: "Lunch",
    rating: "5.0",
    sales: "1,400",
    intrest: "17%",
  },
  {
    image: IMAGES.food5,
    title: "Snack Beef Meatball with Vegetable",
    subtitle: "Snack",
    rating: "4.8",
    sales: "1,456",
    intrest: "15%",
  },
];
const tabledata2 = [
  {
    image: IMAGES.food5,
    title: "Beef Steak with Fried Potato",
    subtitle: "Breakfast",
    rating: "5.0",
    sales: "1,210",
    intrest: "20%",
  },
  {
    image: IMAGES.food2,
    title: "Pancake with Honey",
    subtitle: "Breakfast",
    rating: "4.9",
    sales: "1,110",
    intrest: "13&",
  },
  {
    image: IMAGES.food3,
    title: "Japanese Beef Ramen",
    subtitle: "Breakfast",
    rating: "4.8",
    sales: "1,050",
    intrest: "18%",
  },
];
const tabledata3 = [
  {
    image: IMAGES.food2,
    title: "Beef Steak with Fried Potato",
    subtitle: "Lunch",
    rating: "5.0",
    sales: "1,210",
    intrest: "20%",
  },
  {
    image: IMAGES.food2,
    title: "Pancake with Honey",
    subtitle: "Lunch",
    rating: "4.9",
    sales: "1,110",
    intrest: "13&",
  },
  {
    image: IMAGES.food3,
    title: "Japanese Beef Ramen",
    subtitle: "Lunch",
    rating: "4.8",
    sales: "1,050",
    intrest: "18%",
  },
  {
    image: IMAGES.food4,
    title: "Mixed Salad",
    subtitle: "Lunch",
    rating: "5.0",
    sales: "1,400",
    intrest: "17%",
  },
];
const tabledata4 = [
  {
    image: IMAGES.food3,
    title: "Mixed Salad",
    subtitle: "Snack",
    rating: "5.0",
    sales: "1,400",
    intrest: "17%",
  },
  {
    image: IMAGES.food5,
    title: "Snack Beef Meatball with Vegetable",
    subtitle: "Snack",
    rating: "4.8",
    sales: "1,456",
    intrest: "15%",
  },
];

const PublishedBlog = ({publishedBlog,getBlogs, rowNumber}) => {
  const makeRequest = fetchData();
  const navigate = useNavigate();

  const [searchString, setSearchString] = useState("");
  const [blogs, setBlogs] = useState(publishedBlog);
  const [allBlogs, setAllBlogs] = useState(publishedBlog);

  useEffect(() => {
    setBlogs(publishedBlog)
    setAllBlogs(publishedBlog)
  },[publishedBlog])

  useEffect(() => {
    setBlogs(searchString ? allBlogs.filter(item => item.header.toLowerCase().startsWith(searchString.toLowerCase())) : allBlogs)
  },[searchString])


  function changeBlogState(id,state) {
    makeRequest("POST", "/blog/update-blog-status", {
      id,
      status: state,
    })
      .then((res) => {
        getBlogs()
        swal("Done!", `Blog Moved To ${state.toUpperCase()}`, );
      })
      .catch((err) => {
        swal("Oops!", err?.errors[0]?.error, );
        console.log(err);
      });
  }

  function deleteHandler(id) {
    console.log(id);
    makeRequest("DELETE", "/blog/delete-blog", {
      blog_id: id,
    })
      .then((res) => {
        console.log(res);
        swal("Done!", "Blog Deleted", );
      })
      .catch((err) => {
        swal("Done!", err?.errors[0]?.error, );
        console.log(err);
      });
  }

  return (
    <Card>
      {/* <div style={{ display: "flex", marginLeft: "10rem" }}>
        <a
          className="blog-non"
          href="/view-blog"
          style={{ fontSize: "1rem", padding: ".2rem", borderRadius: ".1rem" }}
        >
          All (1)
        </a>
        <div
          style={{
            borderLeft: ".1rem solid #5a9676",
            height: "1.3rem",
            marginTop: ".45rem",
          }}
        ></div>
        <a
          className="blog-head"
          href="/published"
          style={{ fontSize: "1rem", padding: ".2rem", borderRadius: ".1rem" }}
        >
          Published(0)
        </a>
        <div
          style={{
            borderLeft: ".1rem solid #5a9676",
            height: "1.3rem",
            marginTop: ".45rem",
          }}
        ></div>
        <a
          className="blog-non"
          href="/draft"
          style={{ fontSize: "1rem", padding: ".3rem" }}
        >
          Draft(0)
        </a>
        <div
          style={{
            borderLeft: ".1rem solid #5a9676",
            height: "1.3rem",
            marginTop: ".45rem",
          }}
        ></div>
        <a
          className="blog-non"
          href="/trash"
          style={{ fontSize: "1rem", padding: ".3rem" }}
        >
          Trash(0)
        </a>
        <div
          style={{
            borderLeft: ".1rem solid #5a9676",
            height: "1.3rem",
            marginTop: ".45rem",
          }}
        ></div>
      </div> */}
      <Card.Body>
        <Table responsive>
          <thead>
            <tr style={{ background: "#212A50", textAlign: "center" }}>
              <th className="width80">
                <strong>Sl. No.</strong>
              </th>
              <th>
                <strong>Heading</strong>
              </th>
              <th>
                <strong>Author Name</strong>
              </th>
              <th>
                <strong>Date</strong>
              </th>
              <th>
                <strong>Views</strong>
              </th>
              <th>
                <strong>Tags</strong>
              </th>
              <th>
                {" "}
                <strong>Action</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            {blogs &&
              blogs.map((item,i) => {
                return (
                  <tr style={{ textAlign: "center" }}>
                    <td>
                      <strong>{rowNumber+i}</strong>
                    </td>
                    <td>
                      {" "}
                      <a
                        target="_blank"
                        href={`https://learnforcare.co.uk/blog/${item.header.split(" ").join("_")}`}
                      >
                        {item.header}{" "}
                      </a>
                    </td>
                    <td>{item.author}</td>
                    <td>{item.date}</td>
                    <td>{item.views}</td>
                    <td>{item?.tags ? JSON.parse(item.tags).join(",") : ""}</td>
                    <td>
                      {/* <a target="_blank" href={`https://test.learnforcare.co.uk/blog/${item.id}`}>
                          <Button
                          className="me-2"
                            variant="success btn-icon-xxs"
                          >
                            <FaEye />
                          </Button>
                        </a> */}
                      <Button
                        className="me-2"
                        variant="primary btn-icon-xxs"
                        title="Draft"
                        // onClick={() => navigate("/edit-blog",{state:{id:item.id}})}
                        onClick={() => changeBlogState(item.id,"draft")}
                      >
                        <FaDownload />
                      </Button>

                      <Button
                        className=""
                        variant="secondary btn-icon-xxs"
                        title="Trash"
                        onClick={() => changeBlogState(item.id,"trash")}
                      >
                        <FaTrash />
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default PublishedBlog;
