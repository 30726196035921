import React, { useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import { IMAGES } from "../Dashboard/Content";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { BsFillEyeFill, BsEyeSlashFill } from "react-icons/bs";
import swal from "sweetalert";
import Swal from "sweetalert2";
import {
  Row,
  Col,
  Card,
  Button,
  Dropdown,
  ButtonGroup,
  Tab,
  Nav,
} from "react-bootstrap";
import fetchData from "../../../axios";

const AddNewStudent = () => {
  const makeRequest = fetchData();
  const [showPassword, setShowPassword] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    phone: "",
    country: "",
    city: "",
    type: "",
  });
  function handleOnchange(e) {
    if (e.target.id) {
      validateFormFields(e.target.id);
    }
    setUserData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }

  const validateFormFields = (callParam = "") => {
    let applyError = false;

    // First Name Start
    if (callParam == "submit" || callParam == "first_name") {
      let first_name = document.getElementById("first_name");
      let first_name_msg = document.getElementById("first_name_msg");
      if (!first_name.value || first_name.value.trim() == "") {
        first_name.classList.add("border-danger");
        first_name_msg.innerText = "Please enter the first name!";
        if (applyError == false) {
          first_name.focus();
        }
        applyError = true;
      } else {
        first_name.classList.remove("border-danger");
        first_name_msg.innerText = "";
        applyError = false;
      }
    }
    // First Name End

    // Last Name - Start
    if (callParam == "submit" || callParam == "last_name") {
      let last_name = document.getElementById("last_name");
      let last_name_msg = document.getElementById("last_name_msg");
      if (!last_name.value || last_name.value.trim() == "") {
        last_name.classList.add("border-danger");
        last_name_msg.innerText = "Please enter the last name!";
        if (applyError == false) {
          last_name.focus();
        }
        applyError = true;
      } else {
        last_name.classList.remove("border-danger");
        last_name_msg.innerText = "";
        applyError = false;
      }
    }
    // Last Name - End

    // Email - Start
    if (callParam == "submit" || callParam == "email") {
      let email = document.getElementById("email");
      let email_msg = document.getElementById("email_msg");
      if (!email.value || email.value.trim() == "") {
        email.classList.add("border-danger");
        email_msg.innerText = "Please enter the email address!";
        if (applyError == false) {
          email.focus();
        }
        applyError = true;
      } else if (
        email.value &&
        !String(email.value).match(
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        )
      ) {
        email.classList.add("border-danger");
        email_msg.innerText = "Please enter the valid email address!";
        if (applyError == false) {
          email.focus();
        }
        applyError = true;
      } else {
        email.classList.remove("border-danger");
        email_msg.innerText = "";
        applyError = false;
      }
    }
    // Email - End

    // Phone Number - Start
    if (callParam == "submit" || callParam == "phone") {
      let phone = document.getElementById("phone");
      let phone_msg = document.getElementById("phone_msg");
      if (!phone.value || phone.value.trim() == "") {
        phone.classList.add("border-danger");
        phone_msg.innerText = "Please enter the phone number!";
        if (applyError == false) {
          phone.focus();
        }
        applyError = true;
      } else if (
        phone.value &&
        !String(phone.value).match(
          /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/
        )
      ) {
        phone.classList.add("border-danger");
        phone_msg.innerText = "Please enter a valid UK phone number!";
        if (applyError == false) {
          phone.focus();
        }
        applyError = true;
      } else {
        phone.classList.remove("border-danger");
        phone_msg.innerText = "";
        applyError = false;
      }
    }
    // Phone Number - End

    // Country - Start
    if (callParam == "submit" || callParam == "country") {
      let country = document.getElementById("country");
      let country_msg = document.getElementById("country_msg");
      if (!country.value || country.value.trim() == "") {
        country.classList.add("border-danger");
        country_msg.innerText = "Please select the country name!";
        if (applyError == false) {
          country.focus();
        }
        applyError = true;
      } else {
        country.classList.remove("border-danger");
        country_msg.innerText = "";
        applyError = false;
      }
    }
    // Country - End

    // City - Start
    if (callParam == "submit" || callParam == "city") {
      let city = document.getElementById("city");
      let city_msg = document.getElementById("city_msg");
      if (!city.value || city.value.trim() == "") {
        city.classList.add("border-danger");
        city_msg.innerText = "Please enter the city name!";
        if (applyError == false) {
          city.focus();
        }
        applyError = true;
      } else {
        city.classList.remove("border-danger");
        city_msg.innerText = "";
        applyError = false;
      }
    }
    // City - End

    // Type of the Account - Start
    if (callParam == "submit" || callParam == "type") {
      let type = document.getElementById("type");
      let type_msg = document.getElementById("type_msg");
      if (!type.value || type.value.trim() == "") {
        type.classList.add("border-danger");
        type_msg.innerText = "Please select the account type!";
        if (applyError == false) {
          type.focus();
        }
        applyError = true;
      } else {
        type.classList.remove("border-danger");
        type_msg.innerText = "";
        applyError = false;
      }
    }
    // Type of the Account - End

    // Password - Start
    if (callParam == "submit" || callParam == "password") {
      let password = document.getElementById("password");
      let password_msg = document.getElementById("password_msg");
      if (!password.value) {
        password.classList.add("border-danger");
        password_msg.innerText = "Please enter the password!";
        if (applyError == false) {
          password.focus();
        }
        applyError = true;
      } else if (
        password.value &&
        !String(password.value).match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[^\s]{8,}$/
        )
      ) {
        password.classList.add("border-danger");
        password_msg.innerText =
          "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character.";
        if (applyError == false) {
          password.focus();
        }
        applyError = true;
      } else {
        password.classList.remove("border-danger");
        password_msg.innerText = "";
        applyError = false;
      }
    }
    // Password - End

    if (callParam == "submit" && applyError == false) {
      return applyError;
    } else {
      return true;
    }
  };

  function submit(e) {
    e.preventDefault();
    if (validateFormFields("submit") == false) {
      makeRequest("POST", "/info/create-user", userData)
        .then((res) => {
          if (userData.type == "individual") {
            // swal("Done!", "Individual Successfully Created", "success");
            Swal.fire({
              title: "Done",
              icon: "success",
              text: "Individual Account Created Successfully",
              showCancelButton: false,
              confirmButtonText: "Ok",
              confirmButtonColor: "green",
              // cancelButtonColor:  'red',
              cancelButtonText: "No",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/individual";
              }
            });
            // window.location.href = "/individual";
          } else {
            // swal("Done!", "Company Successfully Created", "success");
            Swal.fire({
              title: "Done",
              icon: "success",
              text: "Company Account Created Successfully",
              showCancelButton: false,
              confirmButtonText: "Ok",
              confirmButtonColor: "green",
              // cancelButtonColor:  'red',
              cancelButtonText: "No",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/company";
              }
            });
            // window.location.href = "/company";
          }
        })
        .catch((err) => {
          // swal("Oops!", err.data.errors[0].error, "error");
          // console.log(err);
          // swal("Oops!", JSON.stringify(err.data.errors[0]), "error");
          if (
            err &&
            err.data.errors[0].message == "values not acceptable" &&
            err.data.errors[0].code == "406"
          ) {
            swal("", "This user is already exist!", "error");
          }
        });
      // console.log(userData);
    }
  }

  return (
    <>
      <div className="row">
        {/* <div className="col-xl-1"></div> */}
        <div className="col-xl-12">
          <div className="card" style={{ borderRadius: "20px" }}>
            <div className="card-header">
              <h3 className="mb-0">Add User Details</h3>
            </div>
            <div className="card-body">
              <form className="row g-3  d-flex justify-content-end  " autoComplete="off">
                <div className="container">
                  <div className="row p-2 pt-0">
                    <div className="col-sm-6 mb-3">
                      <label className="text-black" htmlFor="FormControlInput1">
                        First Name<span className="required">*</span>
                      </label>
                      <input
                        style={{ background: "#f7fbff" }}
                        onChange={(e) => handleOnchange(e)}
                        type="text"
                        className="form-control "
                        id="first_name"
                        placeholder="First Name"
                        name="first_name"
                        value={userData.first_name}
                      />
                      <div
                        className="text-danger mb-0"
                        id="first_name_msg"
                        style={{ fontSize: "80%" }}
                      ></div>
                    </div>

                    <div className="col-sm-6 mb-3">
                      <label className="text-black" htmlFor="FormControlInput1">
                        Last Name<span className="required">*</span>
                      </label>
                      <input
                        style={{ background: "#f7fbff" }}
                        onChange={(e) => handleOnchange(e)}
                        type="text"
                        className="form-control "
                        id="last_name"
                        name="last_name"
                        placeholder="Last Name"
                        value={userData.last_name}
                      />
                      <div
                        className="text-danger mb-0"
                        id="last_name_msg"
                        style={{ fontSize: "80%" }}
                      ></div>
                    </div>

                    <div className="col-sm-6 mb-3">
                      <label className="text-black" htmlFor="FormControlInput1">
                        Email<span className="required">*</span>
                      </label>
                      <input
                        style={{ background: "#f7fbff" }}
                        onChange={(e) => handleOnchange(e)}
                        type="email"
                        className="form-control "
                        id="email"
                        placeholder="name@example.com"
                        name="email"
                        value={userData.email}
                        autocomplete="off"
                      />
                      <div
                        className="text-danger mb-0"
                        id="email_msg"
                        style={{ fontSize: "80%" }}
                      ></div>
                    </div>

                    <div className="col-sm-6 mb-3">
                      <label className="text-black" htmlFor="FormControlInput1">
                        Phone<span className="required">*</span>
                      </label>
                      <input
                        style={{ background: "#f7fbff" }}
                        onChange={(e) => handleOnchange(e)}
                        type="number"
                        className="form-control "
                        id="phone"
                        name="phone"
                        placeholder="Phone"
                        value={userData.phone}
                      />
                      <div
                        className="text-danger mb-0"
                        id="phone_msg"
                        style={{ fontSize: "80%" }}
                      ></div>
                    </div>

                    <div className="col-sm-6 mb-3">
                      <label className="text-black" htmlFor="FormControlInput1">
                        Country<span className="required">*</span>
                      </label>
                      <select
                        style={{ background: "#f7fbff" }}
                        onChange={(e) => handleOnchange(e)}
                        className="form-control "
                        id="country"
                        name="country"
                      >
                        <option value="">Select</option>
                        <option value="United Kingdom">United Kingdom</option>
                      </select>
                      <div
                        className="text-danger mb-0"
                        id="country_msg"
                        style={{ fontSize: "80%" }}
                      ></div>
                    </div>

                    <div className="col-sm-6 mb-3">
                      <label className="text-black" htmlFor="FormControlInput1">
                        City<span className="required">*</span>
                      </label>
                      <input
                        style={{ background: "#f7fbff" }}
                        onChange={(e) => handleOnchange(e)}
                        type="text"
                        className="form-control "
                        id="city"
                        name="city"
                        placeholder="City"
                        value={userData.city}
                      />
                      <div
                        className="text-danger mb-0"
                        id="city_msg"
                        style={{ fontSize: "80%" }}
                      ></div>
                    </div>

                    <div className="col-sm-6 mb-3">
                      <label className="text-black" htmlFor="FormControlInput1">
                        Type of User
                        <span className="required">*Default (Individual)</span>
                      </label>
                      <select
                        style={{ background: "#f7fbff" }}
                        onChange={(e) => handleOnchange(e)}
                        className="form-control "
                        id="type"
                        name="type"
                      >
                        <option value="">Select</option>
                        <option value="individual">Individual</option>
                        <option value="company">Company</option>
                      </select>
                      <div
                        className="text-danger mb-0"
                        id="type_msg"
                        style={{ fontSize: "80%" }}
                      ></div>
                    </div>

                    <div className="col-sm-6 mb-3">
                      <label htmlFor="pas" className="form-label text-primary">
                        Password<span className="required">*</span>
                      </label>
                      <div style={{ position: "relative" }}>
                        <input
                          style={{ background: "#f7fafc" }}
                          type={showPassword ? "text" : "password"}
                          name="password"
                          value={userData.password}
                          onChange={(e) => handleOnchange(e)}
                          className="form-control"
                          id="password"
                          placeholder="Password"
                        />
                        <div
                          style={{
                            position: "absolute",
                            cursor: "pointer",
                            top: ".85rem",
                            right: ".7rem",
                          }}
                          id="pasToggle"
                          onClick={() => setShowPassword((prev) => !prev)}
                        >
                          {showPassword ? (
                            <BsEyeSlashFill />
                          ) : (
                            <BsFillEyeFill />
                          )}
                        </div>
                        <div
                          className="text-danger mb-0"
                          id="password_msg"
                          style={{ fontSize: "80%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group d-flex justify-content-center p-2 mb-4">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={submit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-xl-12">
                <div className="card">
                    <div className="card-header">
                        <h5 className="mb-0">Parents Details</h5>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-xl-6 col-sm-6">
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput8" className="form-label text-primary">First Name<span className="required">*</span></label>
                                    <input type="text" className="form-control" id="exampleFormControlInput8" placeholder="Mana" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput9" className="form-label text-primary">Email<span className="required">*</span></label>
                                    <input type="email" className="form-control" id="exampleFormControlInput9" placeholder="hello@example.com" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlTextarea2" className="form-label text-primary">Address<span className="required">*</span></label>
                                    <textarea className="form-control" id="exampleFormControlTextarea2" rows="6" />
                                </div>
                            </div>
                            <div className="col-xl-6 col-sm-6">
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput10" className="form-label text-primary">Last Name<span className="required">*</span></label>
                                    <input type="text" className="form-control" id="exampleFormControlInput10" placeholder="Wick" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput11" className="form-label text-primary">Phone Number<span className="required">*</span></label>
                                    <input type="number" className="form-control" id="exampleFormControlInput11" placeholder="+123456789" />
                                </div>
                                <label className="form-label text-primary">Payments<span className="required">*</span></label>
                                <div className="d-flex align-items-center">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label className="form-check-label font-w500" htmlFor="flexCheckDefault">Cash</label>
                                    </div>
                                    <div className="form-check ms-3">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault1" />
                                        <label className="form-check-label font-w500" htmlFor="flexCheckDefault1">Online</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <button className="btn btn-outline-primary me-3">Save as Draft</button>
                            <button className="btn btn-primary" type="button">Save</button>
                        </div>
                    </div>
                </div>
            </div> */}
    </>
  );
};

export default AddNewStudent;
