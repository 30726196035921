import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Nav, Tab, Dropdown } from "react-bootstrap";
import { IMAGES, SVGICON } from "../Dashboard/Content";
import circle from "./../../../images/circle.svg";
import PageTitle from "../../layouts/PageTitle";
import { Row, Col, Card, Table, Badge, ProgressBar } from "react-bootstrap";
import fetchData from "../../../axios";
import { MdDelete } from "react-icons/md";
import swal from "sweetalert";
import { RiChatDeleteFill } from "react-icons/ri";
import { Button, ButtonGroup } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { BiSolidEdit } from "react-icons/bi";
import { FaDownload } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import PublishedBlog from "./PublishedBlog";
import BlogTrash from "./TrashBlog";
import BlogDraft from "./DraftBlog";

import Tabs from "react-bootstrap/Tabs";

const svg1 = (
  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
    </g>
  </svg>
);

const tabledata = [
  {
    image: IMAGES.food1,
    title: "Beef Steak with Fried Potato",
    subtitle: "Dinner",
    rating: "5.0",
    sales: "1,210",
    intrest: "20%",
  },
  {
    image: IMAGES.food2,
    title: "Pancake with Honey",
    subtitle: "Breakfast",
    rating: "4.9",
    sales: "1,110",
    intrest: "13&",
  },
  {
    image: IMAGES.food3,
    title: "Japanese Beef Ramen",
    subtitle: "Lunch",
    rating: "4.8",
    sales: "1,050",
    intrest: "18%",
  },
  {
    image: IMAGES.food4,
    title: "Mixed Salad",
    subtitle: "Lunch",
    rating: "5.0",
    sales: "1,400",
    intrest: "17%",
  },
  {
    image: IMAGES.food5,
    title: "Snack Beef Meatball with Vegetable",
    subtitle: "Snack",
    rating: "4.8",
    sales: "1,456",
    intrest: "15%",
  },
];
const tabledata2 = [
  {
    image: IMAGES.food5,
    title: "Beef Steak with Fried Potato",
    subtitle: "Breakfast",
    rating: "5.0",
    sales: "1,210",
    intrest: "20%",
  },
  {
    image: IMAGES.food2,
    title: "Pancake with Honey",
    subtitle: "Breakfast",
    rating: "4.9",
    sales: "1,110",
    intrest: "13&",
  },
  {
    image: IMAGES.food3,
    title: "Japanese Beef Ramen",
    subtitle: "Breakfast",
    rating: "4.8",
    sales: "1,050",
    intrest: "18%",
  },
];
const tabledata3 = [
  {
    image: IMAGES.food2,
    title: "Beef Steak with Fried Potato",
    subtitle: "Lunch",
    rating: "5.0",
    sales: "1,210",
    intrest: "20%",
  },
  {
    image: IMAGES.food2,
    title: "Pancake with Honey",
    subtitle: "Lunch",
    rating: "4.9",
    sales: "1,110",
    intrest: "13&",
  },
  {
    image: IMAGES.food3,
    title: "Japanese Beef Ramen",
    subtitle: "Lunch",
    rating: "4.8",
    sales: "1,050",
    intrest: "18%",
  },
  {
    image: IMAGES.food4,
    title: "Mixed Salad",
    subtitle: "Lunch",
    rating: "5.0",
    sales: "1,400",
    intrest: "17%",
  },
];
const tabledata4 = [
  {
    image: IMAGES.food3,
    title: "Mixed Salad",
    subtitle: "Snack",
    rating: "5.0",
    sales: "1,400",
    intrest: "17%",
  },
  {
    image: IMAGES.food5,
    title: "Snack Beef Meatball with Vegetable",
    subtitle: "Snack",
    rating: "4.8",
    sales: "1,456",
    intrest: "15%",
  },
];

const Food = () => {
  const makeRequest = fetchData();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState("blogs");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowNumber, setRowNumber] = useState(1);
  const [allRecords, setAllRecords] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [publishedBlog, setPublishedBlog] = useState([]);
  const [trashedBlogs, setTrashedBlog] = useState([]);
  const [draftedBlogs, setDraftedBlog] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [hostUrl, setHostUrl] = useState("https://learnforcare.co.uk/");

  const [blogs, setBlogs] = useState([]);
  const [allBlogs, setAllBlogs] = useState([]);
  useEffect(() => {
    getBlogs();
  }, [tabValue]);

  function getBlogs() {
    console.log("res.data.response");
    makeRequest("GET", "/blog/get-all-blog")
      .then((res) => {
        // console.log("res.data.response");
        // console.log(res.data.response);
        if (tabValue == "blogs") {
          setAllRecords(res.data.response.reverse()); //All Records
        } else if (tabValue == "published") {
          setAllRecords(
            res.data.response.filter((blog) => blog.state == "published")
          ); //All Records
        } else if (tabValue == "trash") {
          setAllRecords(
            res.data.response.filter((blog) => blog.state == "trash")
          ); //All Records
        } else if (tabValue == "draft") {
          setAllRecords(
            res.data.response.filter((blog) => blog.state == "draft")
          ); //All Records
        }
        setBlogs(res.data.response.reverse());
        setAllBlogs(res.data.response.reverse());
        setPublishedBlog(
          res.data.response.filter((blog) => blog.state == "published")
        );
        setTrashedBlog(
          res.data.response.filter((blog) => blog.state == "trash")
        );
        setDraftedBlog(
          res.data.response.filter((blog) => blog.state == "draft")
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    setBlogs(
      searchString
        ? allBlogs.filter((item) =>
            item.header.toLowerCase().startsWith(searchString)
          )
        : allBlogs
    );
    // Set HostUrl
    let host = window.location.host;
    if (host) {
      if (
        host == "admin.learnforcare.co.uk" ||
        host == "www.admin.learnforcare.co.uk"
      ) {
        setHostUrl("https://learnforcare.co.uk/");
      } else if (
        host == "admin.test.learnforcare.co.uk" ||
        host == "www.admin.test.learnforcare.co.uk"
      ) {
        setHostUrl("https://testing.learnforcare.co.uk/");
      } else {
        setHostUrl("http://localhost:3004/");
      }
    }
    // Set HostUrl
  }, [searchString]);

  function deleteHandler(id) {
    console.log(id);
    makeRequest("DELETE", "/blog/delete-blog", {
      blog_id: id,
    })
      .then((res) => {
        getBlogs();
        // navigate("/view-blog");
        swal("Done!", "Blog Deleted", "success");
      })
      .catch((err) => {
        swal("Done!", err?.errors[0]?.error, "success");
        console.log(err);
      });
  }

  function blogStatusHandler(id, status) {
    makeRequest("POST", "/blog/update-blog-status", {
      id,
      status,
    })
      .then((res) => {
        getBlogs();
        console.log(res);
        swal("Done!", `Blog Moved to ${status.toUpperCase()}`, "success");
      })
      .catch((err) => {
        swal("Oops!", err?.errors[0]?.error, "error");
        console.log(err);
      });
  }

  // Filter Starts
  const matchesSearchText = (blogsDetails) => {
    const searchText = inputValue.toLowerCase();
    if (tabValue == "blogs") {
      return (
        String(blogsDetails?.header).toLowerCase().includes(searchText) ||
        String(blogsDetails?.author).toLowerCase().includes(searchText) ||
        String(blogsDetails?.date).toLowerCase().includes(searchText) ||
        String(blogsDetails?.views).toLowerCase().includes(searchText) ||
        String(blogsDetails?.state).toLowerCase().includes(searchText)
      );
    } else if (tabValue == "published") {
      return (
        String(blogsDetails?.header).toLowerCase().includes(searchText) ||
        String(blogsDetails?.author).toLowerCase().includes(searchText) ||
        String(blogsDetails?.date).toLowerCase().includes(searchText) ||
        String(blogsDetails?.views).toLowerCase().includes(searchText) ||
        String(
          blogsDetails?.tags ? JSON.parse(blogsDetails?.tags).join(",") : ""
        )
          .toLowerCase()
          .includes(searchText)
      );
    } else if (tabValue == "draft") {
      return (
        String(blogsDetails?.header).toLowerCase().includes(searchText) ||
        String(blogsDetails?.author).toLowerCase().includes(searchText) ||
        String(blogsDetails?.date).toLowerCase().includes(searchText) ||
        String(blogsDetails?.views).toLowerCase().includes(searchText) ||
        String(
          blogsDetails?.tags ? JSON.parse(blogsDetails?.tags).join(",") : ""
        )
          .toLowerCase()
          .includes(searchText)
      );
    } else if (tabValue == "trash") {
      return (
        String(blogsDetails?.header).toLowerCase().includes(searchText) ||
        String(blogsDetails?.author).toLowerCase().includes(searchText) ||
        String(blogsDetails?.date).toLowerCase().includes(searchText) ||
        String(blogsDetails?.views).toLowerCase().includes(searchText) ||
        String(
          blogsDetails?.tags ? JSON.parse(blogsDetails?.tags).join(",") : ""
        )
          .toLowerCase()
          .includes(searchText)
      );
    }
  };

  // Filter applied jobs based on the search text
  const filterRows = allRecords ? allRecords.filter(matchesSearchText) : [];

  const recordsPage = 10;
  const currentRecords = inputValue ? filterRows : allRecords; // Use filtered records if searching
  const npage = Math.ceil(currentRecords.length / recordsPage);
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;
  const records = currentRecords.slice(firstIndex, lastIndex); // Apply pagination on filtered or full records
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      setRowNumber(10 * (currentPage - 1) - 9);
    }
  }

  function changeCPage(id) {
    setRowNumber(10 * id - 9);
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
      setRowNumber(10 * (currentPage + 1) - 9);
    }
  }

  const customStyles = {
    tabsContainer: {
      margin: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "1px solid grey",
      borderRadius: "20px",
    },
    tabLink: {
      backgroundColor: "#212450",
      color: "white",
      // fontSize: "16px",
      fontWeight: "bold",
      padding: "10px",
      borderRadius: "15px",
      margin: "0px",
      textAlign: "center",
      textTransform: "uppercase",
      transition: "background-color 0.3s ease",
      cursor: "pointer",
    },
    tabLinkActive: {
      backgroundColor: "#4CAF50",
      color: "white",
      // fontSize: "18px",
      borderRadius: "20px",
    },
    tabLinkHover: {
      backgroundColor: "#555",
      color: "white",
    },
  };

  return (
    <div className="card p-2" style={{borderRadius:"20px"}}>
      <Col lg={12}>
        <Tabs
          defaultActiveKey={tabValue}
          id="uncontrolled-tab-example"
          fill
          className="mb-3 mt-3 m-2 border border-grey p-3"
          style={{ color: "white", borderRadius: "20px" }}
          onSelect={(key) => {
            setTabValue(key);
            setInputValue("");
          }}
        >
          <Tab
            eventKey="blogs"
            title={
              <span
                style={{
                  ...customStyles.tabLink,
                  ...(tabValue === "blogs" && customStyles.tabLinkActive),
                }}
              >
                {`All Blogs (${blogs.length})`}
              </span>
            }
          >
            <Card>
              <Card.Header>
                <h3>All Blogs</h3>
                <div className="input-group search-area mb-md-0 mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search here..."
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                  <span className="input-group-text">
                    <Link to={"#"}>
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z"
                          fill="#01A3FF"
                        />
                      </svg>
                    </Link>
                  </span>
                </div>
              </Card.Header>
              <Card.Body>
                <div className="col-sm-12 wow fadeInUp" data-wow-delay="1.5s">
                  <div className="table-responsive full-data">
                    <div
                      id="example-student_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <Table responsive>
                        <thead>
                          <tr
                            style={{
                              background: "#212A50",
                              textAlign: "center",
                            }}
                          >
                            <th className="width80">
                              <strong>Sl. No.</strong>
                            </th>
                            <th>
                              <strong>Heading</strong>
                            </th>
                            <th>
                              <strong>Author Name</strong>
                            </th>
                            <th>
                              <strong>Date</strong>
                            </th>
                            <th>
                              <strong>Views</strong>
                            </th>
                            <th>
                              <strong>Status</strong>
                            </th>
                            <th>
                              {" "}
                              <strong>Action</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {records &&
                            records?.map((item, idx) => {
                              return (
                                <tr style={{ textAlign: "center" }}>
                                  <td>
                                    <strong>{rowNumber + idx}</strong>
                                  </td>
                                  <td>
                                    {" "}
                                    <a
                                      target="_blank"
                                      href={`${hostUrl}blog/${item.header_url}`}
                                    >
                                      {item.header.slice(0, 60)}..{" "}
                                    </a>
                                  </td>
                                  <td>{item.author}</td>
                                  <td>{item.date}</td>
                                  <td>{item.views}</td>
                                  <td>
                                    <span
                                      className="badge badge-primary"
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {item.state}
                                    </span>
                                  </td>
                                  <td>
                                    <a
                                      target="_blank"
                                      href={`${hostUrl}blog/${item.header_url}`}
                                    >
                                      <Button
                                        className="me-2"
                                        variant="success btn-icon-xxs"
                                        title="View"
                                      >
                                        <FaEye />
                                      </Button>
                                    </a>
                                    <Button
                                      className="me-2"
                                      variant="primary btn-icon-xxs"
                                      title="Edit"
                                      onClick={() =>
                                        navigate("/edit-blog", {
                                          state: { id: item.id },
                                        })
                                      }
                                    >
                                      <BiSolidEdit />
                                    </Button>
                                    <Button
                                      className="me-2"
                                      variant="dark btn-icon-xxs"
                                      title="Draft"
                                      onClick={() =>
                                        blogStatusHandler(item.id, "draft")
                                      }
                                    >
                                      <FaDownload />
                                    </Button>
                                    <Button
                                      className="me-2"
                                      variant="secondary btn-icon-xxs"
                                      title="Trash"
                                      onClick={() =>
                                        blogStatusHandler(item.id, "trash")
                                      }
                                    >
                                      <FaTrash />
                                    </Button>
                                    <Button
                                      className="btn btn-danger"
                                      title="Delete"
                                      onClick={() => deleteHandler(item.id)}
                                      variant="danger btn-icon-xxs"
                                    >
                                      <RiChatDeleteFill />
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                      {/* Pagination Start */}
                      <div className="d-sm-flex text-center justify-content-end align-items-center">
                        <div className="dataTables_paginate paging_simple_numbers justify-content-center">
                          <Link
                            className="paginate_button previous disabled"
                            to="#"
                            onClick={prePage}
                          >
                            <i className="fa-solid fa-angle-left" />
                          </Link>
                          <span>
                            {number.map((n, i) => (
                              <Link
                                className={`paginate_button ${
                                  currentPage === n ? "current" : ""
                                }`}
                                key={i}
                                onClick={() => changeCPage(n)}
                              >
                                {n}
                              </Link>
                            ))}
                          </span>
                          <Link
                            className="paginate_button next"
                            to="#"
                            onClick={nextPage}
                          >
                            <i className="fa-solid fa-angle-right" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Pagination End */}
              </Card.Body>
            </Card>
          </Tab>

          <Tab
            eventKey="published"
            title={
              <span
                style={{
                  ...customStyles.tabLink,
                  ...(tabValue === "published" && customStyles.tabLinkActive),
                }}
              >
                {`Published (${publishedBlog.length})`}
              </span>
            }
          >
            <Card.Header>
              <h3>Published Blogs</h3>
              <div className="input-group search-area mb-md-0 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search here..."
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
                <span className="input-group-text">
                  <Link to={"#"}>
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z"
                        fill="#01A3FF"
                      />
                    </svg>
                  </Link>
                </span>
              </div>
            </Card.Header>
            <div className="col-sm-12 wow fadeInUp" data-wow-delay="1.5s">
              <div className="table-responsive full-data">
                <div
                  id="example-student_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <PublishedBlog
                    publishedBlog={records}
                    getBlogs={getBlogs}
                    rowNumber={rowNumber}
                  />
                  {/* Pagination Start */}
                  <div className="d-sm-flex text-center justify-content-end align-items-center">
                    <div className="dataTables_paginate paging_simple_numbers justify-content-center">
                      <Link
                        className="paginate_button previous disabled"
                        to="#"
                        onClick={prePage}
                      >
                        <i className="fa-solid fa-angle-left" />
                      </Link>
                      <span>
                        {number.map((n, i) => (
                          <Link
                            className={`paginate_button ${
                              currentPage === n ? "current" : ""
                            }`}
                            key={i}
                            onClick={() => changeCPage(n)}
                          >
                            {n}
                          </Link>
                        ))}
                      </span>
                      <Link
                        className="paginate_button next"
                        to="#"
                        onClick={nextPage}
                      >
                        <i className="fa-solid fa-angle-right" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Pagination End */}
          </Tab>

          <Tab
            eventKey="draft"
            title={
              <span
                style={{
                  ...customStyles.tabLink,
                  ...(tabValue === "draft" && customStyles.tabLinkActive),
                }}
              >
                {`Draft (${draftedBlogs.length})`}
              </span>
            }
          >
            <Card.Header>
              <h3>Drafted Blogs</h3>
              <div className="input-group search-area mb-md-0 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search here..."
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
                <span className="input-group-text">
                  <Link to={"#"}>
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z"
                        fill="#01A3FF"
                      />
                    </svg>
                  </Link>
                </span>
              </div>
            </Card.Header>
            <div className="col-sm-12 wow fadeInUp" data-wow-delay="1.5s">
              <div className="table-responsive full-data">
                <div
                  id="example-student_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <BlogDraft
                    draftedBlogs={records}
                    getBlogs={getBlogs}
                    rowNumber={rowNumber}
                  />
                  {/* Pagination Start */}
                  <div className="d-sm-flex text-center justify-content-end align-items-center">
                    <div className="dataTables_paginate paging_simple_numbers justify-content-center">
                      <Link
                        className="paginate_button previous disabled"
                        to="#"
                        onClick={prePage}
                      >
                        <i className="fa-solid fa-angle-left" />
                      </Link>
                      <span>
                        {number.map((n, i) => (
                          <Link
                            className={`paginate_button ${
                              currentPage === n ? "current" : ""
                            }`}
                            key={i}
                            onClick={() => changeCPage(n)}
                          >
                            {n}
                          </Link>
                        ))}
                      </span>
                      <Link
                        className="paginate_button next"
                        to="#"
                        onClick={nextPage}
                      >
                        <i className="fa-solid fa-angle-right" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Pagination End */}
          </Tab>

          <Tab
            eventKey="trash"
            title={
              <span
                style={{
                  ...customStyles.tabLink,
                  ...(tabValue === "trash" && customStyles.tabLinkActive),
                }}
              >
                {`Trash (${trashedBlogs.length})`}
              </span>
            }
          >
            <Card.Header>
              <h3>Trashed Blogs</h3>
              <div className="input-group search-area mb-md-0 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search here..."
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
                <span className="input-group-text">
                  <Link to={"#"}>
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z"
                        fill="#01A3FF"
                      />
                    </svg>
                  </Link>
                </span>
              </div>
            </Card.Header>
            <div className="col-sm-12 wow fadeInUp" data-wow-delay="1.5s">
              <div className="table-responsive full-data">
                <div
                  id="example-student_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <BlogTrash
                    trashedBlogs={records}
                    getBlogs={getBlogs}
                    rowNumber={rowNumber}
                  />
                  {/* Pagination Start */}
                  <div className="d-sm-flex text-center justify-content-end align-items-center">
                    <div className="dataTables_paginate paging_simple_numbers justify-content-center">
                      <Link
                        className="paginate_button previous disabled"
                        to="#"
                        onClick={prePage}
                      >
                        <i className="fa-solid fa-angle-left" />
                      </Link>
                      <span>
                        {number.map((n, i) => (
                          <Link
                            className={`paginate_button ${
                              currentPage === n ? "current" : ""
                            }`}
                            key={i}
                            onClick={() => changeCPage(n)}
                          >
                            {n}
                          </Link>
                        ))}
                      </span>
                      <Link
                        className="paginate_button next"
                        to="#"
                        onClick={nextPage}
                      >
                        <i className="fa-solid fa-angle-right" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Pagination End */}
          </Tab>
        </Tabs>
      </Col>
    </div>
  );
};

export default Food;

{
  /* <div style={{ display: "flex", marginLeft: "10rem" }}>
            <a
              className="blog-head"
              href="/view-blog"
              style={{
                fontSize: "1rem",
                padding: ".2rem",
                borderRadius: ".1rem",
              }}
            >
              All (1)
            </a>
            <div
              style={{
                borderLeft: ".1rem solid #5a9676",
                height: "1.3rem",
                marginTop: ".45rem",
              }}
            ></div>
            <a
              className="blog-non"
              href="/published"
              style={{ fontSize: "1rem", padding: ".3rem" }}
            >
              Published(0)
            </a>
            <div
              style={{
                borderLeft: ".1rem solid #5a9676",
                height: "1.3rem",
                marginTop: ".45rem",
              }}
            ></div>
            <a
              className="blog-non"
              href="/draft"
              style={{ fontSize: "1rem", padding: ".3rem" }}
            >
              Draft(0)
            </a>
            <div
              style={{
                borderLeft: ".1rem solid #5a9676",
                height: "1.3rem",
                marginTop: ".45rem",
              }}
            ></div>
            <a
              className="blog-non"
              href="/trash"
              style={{ fontSize: "1rem", padding: ".3rem" }}
            >
              Trash(0)
            </a>
            <div
              style={{
                borderLeft: ".1rem solid #5a9676",
                height: "1.3rem",
                marginTop: ".45rem",
              }}
            ></div>
          </div> */
}
