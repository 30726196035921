import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Nav, Tab, Dropdown } from "react-bootstrap";
import { IMAGES, SVGICON } from "../Dashboard/Content";
import circle from "./../../../images/circle.svg";
import PageTitle from "../../layouts/PageTitle";
import { Row, Col, Card, Table, Badge, ProgressBar } from "react-bootstrap";
import fetchData from "../../../axios";
import { MdDelete } from "react-icons/md";
import swal from "sweetalert";
import { RiChatDeleteFill } from "react-icons/ri";
import { Button, ButtonGroup } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { BiSolidEdit } from "react-icons/bi";
import { FaTrashRestore } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const BlogTrash = ({ trashedBlogs, getBlogs, rowNumber }) => {
  const makeRequest = fetchData();
  const navigate = useNavigate();

  // const [blogs, setBlogs] = useState([]);
  // makeRequest("GET", "/blog/get-all-blog")
  //   .then((res) => {
  //     setBlogs(res.data.response);
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });

  const [searchString, setSearchString] = useState("");
  const [blogs, setBlogs] = useState(trashedBlogs);
  const [allBlogs, setAllBlogs] = useState(trashedBlogs);

  useEffect(() => {
    setBlogs(trashedBlogs)
    setAllBlogs(trashedBlogs)  
  },[trashedBlogs])

  useEffect(() => {
    setBlogs(searchString ? allBlogs.filter(item => item.header.toLowerCase().startsWith(searchString.toLowerCase())) : allBlogs)
  },[searchString])

  function blogStatusHandler(id, status) {
    makeRequest("POST", "/blog/update-blog-status", {
      id,
      status,
    })
      .then((res) => {
        getBlogs();
        console.log(res);
        swal("Done!", `Blog Moved To ${status.toUpperCase()}`, );
      })
      .catch((err) => {
        swal("Oops!", err?.errors[0]?.error, );
        console.log(err);
      });
  }

  function deleteHandler(id) {
    console.log(id);
    makeRequest("DELETE", "/blog/delete-blog", {
      blog_id: id,
    })
      .then((res) => {
        console.log(res);
        getBlogs();
        swal("Done!", "Blog Deleted", );
      })
      .catch((err) => {
        swal("Done!", err?.errors[0]?.error, );
        console.log(err);
      });
  }
  return (
    <Card>
      {/* <div style={{display:"flex", marginLeft:"10rem",}}><a className="blog-non" href="/view-blog" style={{fontSize:"1rem", padding:'.2rem',borderRadius:'.1rem'}}>All (1)</a><div style={{borderLeft:'.1rem solid #5a9676', height:'1.3rem',marginTop:".45rem"}}></div>
          <a className="blog-non" href="/published"  style={{fontSize:"1rem", padding:'.3rem'}}>Published(0)</a><div style={{borderLeft:'.1rem solid #5a9676', height:'1.3rem',marginTop:".45rem"}}></div>
          <a className="blog-non" href="/draft" style={{fontSize:"1rem", padding:'.3rem'}}>Draft(0)</a><div style={{borderLeft:'.1rem solid #5a9676', height:'1.3rem',marginTop:".45rem"}}></div>
          <a className="blog-head" href="/trash" style={{fontSize:"1rem", padding:'.2rem',borderRadius:'.1rem'}}>Trash(0)</a><div style={{borderLeft:'.1rem solid #5a9676', height:'1.3rem',marginTop:".45rem"}}></div>
          </div> */}

      <Card.Body>
        <Table responsive>
          <thead>
            <tr style={{ background: "#212A50", textAlign: "center" }}>
              <th className="width80">
                <strong>Sl. No.</strong>
              </th>
              <th>
                <strong>Heading</strong>
              </th>
              <th>
                <strong>Author Name</strong>
              </th>
              <th>
                <strong>Date</strong>
              </th>
              <th>
                <strong>Views</strong>
              </th>
              <th>
                <strong>Tags</strong>
              </th>
              <th>
                {" "}
                <strong>Action</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            {blogs &&
              blogs.map((item,i) => {
                return (
                  <tr style={{ textAlign: "center" }}>
                    <td>
                      <strong>{rowNumber+i}</strong>
                    </td>
                    <td>
                      {" "}
                      <a
                        target="_blank"
                        href={`https://learnforcare.co.uk/blog/${item.header.split(" ").join("_")}`}
                      >
                        {item.header}{" "}
                      </a>
                    </td>
                    <td>{item.author}</td>
                    <td>{item.date}</td>
                    <td>{item.views}</td>
                    <td>{item?.tags ? JSON.parse(item.tags).join(",") : ""}</td>
                    <td>
                      {/* <a target="_blank" href={`https://test.learnforcare.co.uk/blog/${item.id}`}>
                          <Button
                          className="me-2"
                            variant="success btn-icon-xxs"
                          >
                            <FaEye />
                          </Button>
                        </a> */}
                      <Button
                        className="me-2"
                        title="Draft"
                        variant="primary btn-icon-xxs"
                        onClick={() => blogStatusHandler(item.id, "draft")}
                      >
                        <FaTrashRestore />
                      </Button>

                      <Button
                        className=""
                        title="Delete"
                        variant="secondary btn-icon-xxs"
                        onClick={() => deleteHandler(item.id)}
                      >
                        < RiChatDeleteFill />
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default BlogTrash;
