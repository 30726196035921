import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { RiChatDeleteFill } from "react-icons/ri";
import { Nav, Tab, Dropdown, Spinner } from "react-bootstrap";
import { IMAGES, SVGICON } from "./Dashboard/Content";
import { FaEye } from "react-icons/fa";
import { Button, ButtonGroup } from "react-bootstrap";
import { BiSolidEdit } from "react-icons/bi";
import PageTitle from "../layouts/PageTitle";
import { Row, Col, Card, Table, Badge, ProgressBar } from "react-bootstrap";
import fetchData from "../../axios";
import { useState } from "react";
import swal from "sweetalert";
import { useEffect } from "react";
import CourseModal from "./Food/CourseModals";
import { MdAssignmentAdd } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { FaTrash } from "react-icons/fa";
// const svg1 = (
//   <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
//     <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
//       <rect x="0" y="0" width="24" height="24"></rect>
//       <circle fill="#000000" cx="5" cy="12" r="2"></circle>
//       <circle fill="#000000" cx="12" cy="12" r="2"></circle>
//       <circle fill="#000000" cx="19" cy="12" r="2"></circle>
//     </g>
//   </svg>
// );

const tabledata = [
  {
    image: IMAGES.food1,
    title: "Beef Steak with Fried Potato",
    subtitle: "Dinner",
    rating: "5.0",
    sales: "1,210",
    intrest: "20%",
  },
  {
    image: IMAGES.food2,
    title: "Pancake with Honey",
    subtitle: "Breakfast",
    rating: "4.9",
    sales: "1,110",
    intrest: "13&",
  },
  {
    image: IMAGES.food3,
    title: "Japanese Beef Ramen",
    subtitle: "Lunch",
    rating: "4.8",
    sales: "1,050",
    intrest: "18%",
  },
  {
    image: IMAGES.food4,
    title: "Mixed Salad",
    subtitle: "Lunch",
    rating: "5.0",
    sales: "1,400",
    intrest: "17%",
  },
  {
    image: IMAGES.food5,
    title: "Snack Beef Meatball with Vegetable",
    subtitle: "Snack",
    rating: "4.8",
    sales: "1,456",
    intrest: "15%",
  },
];
const tabledata2 = [
  {
    image: IMAGES.food5,
    title: "Beef Steak with Fried Potato",
    subtitle: "Breakfast",
    rating: "5.0",
    sales: "1,210",
    intrest: "20%",
  },
  {
    image: IMAGES.food2,
    title: "Pancake with Honey",
    subtitle: "Breakfast",
    rating: "4.9",
    sales: "1,110",
    intrest: "13&",
  },
  {
    image: IMAGES.food3,
    title: "Japanese Beef Ramen",
    subtitle: "Breakfast",
    rating: "4.8",
    sales: "1,050",
    intrest: "18%",
  },
];
const tabledata3 = [
  {
    image: IMAGES.food2,
    title: "Beef Steak with Fried Potato",
    subtitle: "Lunch",
    rating: "5.0",
    sales: "1,210",
    intrest: "20%",
  },
  {
    image: IMAGES.food2,
    title: "Pancake with Honey",
    subtitle: "Lunch",
    rating: "4.9",
    sales: "1,110",
    intrest: "13&",
  },
  {
    image: IMAGES.food3,
    title: "Japanese Beef Ramen",
    subtitle: "Lunch",
    rating: "4.8",
    sales: "1,050",
    intrest: "18%",
  },
  {
    image: IMAGES.food4,
    title: "Mixed Salad",
    subtitle: "Lunch",
    rating: "5.0",
    sales: "1,400",
    intrest: "17%",
  },
];
const tabledata4 = [
  {
    image: IMAGES.food3,
    title: "Mixed Salad",
    subtitle: "Snack",
    rating: "5.0",
    sales: "1,400",
    intrest: "17%",
  },
  {
    image: IMAGES.food5,
    title: "Snack Beef Meatball with Vegetable",
    subtitle: "Snack",
    rating: "4.8",
    sales: "1,456",
    intrest: "15%",
  },
];

const ViewCourse = () => {
  const makeRequest = fetchData();
  const [course, setCourse] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [checked, setChecked] = useState([]);
  const [unchecked, setUnChecked] = useState(true);

  const [searchString, setSearchString] = useState("");
  const [rowNumber, setRowNumber] = useState(1);
  const [allRecords, setAllRecords] = useState([]);

  const [openModalForWorkExp, setOpenModalForWorkExp] = useState(false);

  const [bundles, setBundles] = useState([]);
  const [bundleId, setBundleId] = useState(1);
  const [courseName, setCourseName] = useState("");
  const [hostUrl, setHostUrl] = useState("https://learnforcare.co.uk/");

  useEffect(() => {
    setLoading(true);
    makeRequest("GET", "/course/get-all-course-info")
      .then((res) => {
        setLoading(false);
        // setCourse(res.data.response);
        setAllRecords(res.data.response);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    // Set HostUrl
    let host = window.location.host;
    if (host) {
      if (
        host == "admin.learnforcare.co.uk" ||
        host == "www.admin.learnforcare.co.uk"
      ) {
        setHostUrl("https://learnforcare.co.uk/");
      } else if (
        host == "admin.test.learnforcare.co.uk" ||
        host == "www.admin.test.learnforcare.co.uk"
      ) {
        setHostUrl("https://testing.learnforcare.co.uk/");
      } else {
        setHostUrl("http://localhost:3004/");
      }
    }
    // Set HostUrl
  }, []);

  function assignBundle(userId, count) {
    console.log(bundleId, userId, count);
    let form = new FormData();
    form.append("type", "course");
    form.append("count", count);
    form.append("user_id", userId);
    form.append("bundle_id", bundleId);

    makeRequest("POST", "/info/assign-bundle", form) // assign bundle and assign course is same route
      .then((res) => {
        swal("Assigned!", "Course Assigned", "success");
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleDelete(id) {
    makeRequest("DELETE", `/course/delete/${id}`)
      .then((res) => {
        setCourse((prev) => prev.filter((item) => item.id != Number(id)));
        swal("Done!", "Successfully Deleted", "success");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Filter Starts
  const [inputValue, setInputValue] = useState("");
  const matchesSearchText = (courseDetails) => {
    const searchText = inputValue.toLowerCase();
    return (
      String(courseDetails?.name).toLowerCase().includes(searchText) ||
      String(courseDetails?.course_code).toLowerCase().includes(searchText) ||
      String(courseDetails?.category).toLowerCase().includes(searchText) ||
      String(courseDetails?.description).toLowerCase().includes(searchText) ||
      String(courseDetails?.price).toLowerCase().includes(searchText)
    );
  };

  // Filter applied jobs based on the search text
  const filterRows = allRecords ? allRecords.filter(matchesSearchText) : [];

  const recordsPage = 10;
  const currentRecords = inputValue ? filterRows : allRecords; // Use filtered records if searching
  const npage = Math.ceil(currentRecords.length / recordsPage);
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;
  const records = currentRecords.slice(firstIndex, lastIndex); // Apply pagination on filtered or full records
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      setRowNumber(10 * (currentPage - 1) - 9);
    }
  }

  function changeCPage(id) {
    setRowNumber(10 * id - 9);
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
      setRowNumber(10 * (currentPage + 1) - 9);
    }
  }

  return (
    <div>
      <CourseModal
        setOpenModalForWorkExp={setOpenModalForWorkExp}
        openModalForWorkExp={openModalForWorkExp}
        assignBundle={assignBundle}
        name={courseName}
      />
      <div className="card p-2" style={{ borderRadius: "20px" }}>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <h3>All Courses</h3>
            </Card.Header>
            <Card.Body>
              <div className="col-sm-12">
                <div className="page-title flex-wrap">
                  <div className="input-group search-area mb-md-0 ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search here..."
                      value={inputValue}
                      // onChange={(e) => setSearchString(e.target.value)}
                      onChange={(e) => setInputValue(e.target.value)}
                    />
                    <span className="input-group-text">
                      <Link to={"#"}>
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z"
                            fill="#01A3FF"
                          />
                        </svg>
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 wow fadeInUp" data-wow-delay="1.5s">
                <div className="table-responsive full-data">
                  <div
                    id="example-student_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <Table responsive>
                      <thead>
                        <tr
                          style={{ textAlign: "center", background: "#212A50" }}
                        >
                          <th className="width80">
                            <strong>Sr.No</strong>
                          </th>
                          <th className="width80">
                            <strong>ID</strong>
                          </th>
                          <th>
                            <strong>Course Name</strong>
                          </th>
                          <th>
                            <strong>Category</strong>
                          </th>
                          <th>
                            <strong>Description</strong>
                          </th>
                          <th>
                            <strong>Price</strong>
                          </th>
                          <th>
                            <strong>Status</strong>
                          </th>
                          <th>
                            <strong>Action</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {loading && <Spinner animation="border" variant="primary" />} */}
                        {records &&
                          !loading &&
                          records.map((item, id) => (
                            <tr style={{ textAlign: "center" }}>
                              <td>
                                <strong>{rowNumber + id}</strong>
                              </td>
                              <td>
                                <strong>{item.course_code}</strong>
                              </td>
                              <td>{item?.name}</td>
                              <td>{item?.category}</td>
                              <td>{item?.description.slice(0, 30)}</td>
                              <td>{item?.price}</td>
                              <td>
                                <Badge bg="" className="light badge-success">
                                  Active
                                </Badge>
                              </td>
                              <td>
                                <a
                                  href={`${hostUrl}course/${item.name_url}`}
                                  target="_blank"
                                >
                                  <Button
                                    title="View"
                                    className="me-2"
                                    variant="success btn-icon-xxs"
                                  >
                                    <FaEye />
                                  </Button>
                                </a>
                                <Button
                                  title="Assign"
                                  onClick={() => {
                                    setBundleId(item.id);
                                    setCourseName(item.name);
                                    setOpenModalForWorkExp(true);
                                  }}
                                  className="me-2"
                                  variant="info btn-icon-xxs"
                                >
                                  <MdAssignmentAdd />
                                </Button>

                                <Button
                                  title="Edit"
                                  className="me-2"
                                  variant="primary btn-icon-xxs"
                                  onClick={() =>
                                    navigate("/edit-course", {
                                      state: { id: item.id },
                                    })
                                  }
                                >
                                  <BiSolidEdit />
                                </Button>
                                <Button
                                  title="Delete"
                                  className="me-2"
                                  variant="danger btn-icon-xxs"
                                >
                                  <FaTrash
                                    style={{ fontSize: "1rem" }}
                                    onClick={() => handleDelete(item.id)}
                                  />
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    {/* Pagination Start */}
                    <div className="d-sm-flex text-center justify-content-end align-items-center">
                      <div className="dataTables_paginate paging_simple_numbers justify-content-center">
                        <Link
                          className="paginate_button previous disabled"
                          to="#"
                          onClick={prePage}
                        >
                          <i className="fa-solid fa-angle-left" />
                        </Link>
                        <span>
                          {number.map((n, i) => (
                            <Link
                              className={`paginate_button ${
                                currentPage === n ? "current" : ""
                              }`}
                              key={i}
                              onClick={() => changeCPage(n)}
                            >
                              {n}
                            </Link>
                          ))}
                        </span>
                        <Link
                          className="paginate_button next"
                          to="#"
                          onClick={nextPage}
                        >
                          <i className="fa-solid fa-angle-right" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Pagination End */}
            </Card.Body>
          </Card>
        </Col>
      </div>
    </div>
  );
};

export default ViewCourse;
