import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Nav, Tab, Dropdown } from "react-bootstrap";
import { IMAGES, SVGICON } from "./Dashboard/Content";
import { Button, ButtonGroup } from "react-bootstrap";
import { RiChatDeleteFill } from "react-icons/ri";
import PageTitle from "../layouts/PageTitle";
import { Row, Col, Card, Table, Badge, ProgressBar } from "react-bootstrap";
import fetchData from "../../axios";

const CourseManReport = () => {
  const makeRequest = fetchData();
  const [managerReport, setManagerReport] = useState([]);
  const [allManagerReport, setAllManagerReport] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [rowNumber, setRowNumber] = useState(1);
  const [allRecords, setAllRecords] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setManagerReport(
      searchString
        ? allManagerReport.filter((item) =>
            String(item.first_name)
              .toLowerCase()
              .startsWith(searchString.toLowerCase())
          )
        : allManagerReport
    );
  }, [searchString]);

  useEffect(() => {
    makeRequest("GET", "/info/get-manager-report")
      .then((res) => {
        console.log(res.data.response);
        setManagerReport(res.data.response);
        setAllRecords(res.data.response);
        setAllManagerReport(res.data.response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Filter Starts
  const [inputValue, setInputValue] = useState("");
  const matchesSearchText = (managerDetails) => {
    const searchText = inputValue.toLowerCase();
    return (
      String(managerDetails?.id).toLowerCase().includes(searchText) ||
      String(managerDetails?.first_name + " " + managerDetails?.last_name)
        .toLowerCase()
        .includes(searchText) ||
      String(managerDetails?.individuals_count)
        .toLowerCase()
        .includes(searchText) ||
      String(
        managerDetails?.assigned_bundle_count +
          managerDetails?.purchased_bundle_count
      )
        .toLowerCase()
        .includes(searchText) ||
      String(
        managerDetails?.assigned_course_count +
          managerDetails?.purchased_course_count
      )
        .toLowerCase()
        .includes(searchText)
    );
  };

  // Filter applied jobs based on the search text
  const filterRows = allRecords ? allRecords.filter(matchesSearchText) : [];

  const recordsPage = 10;
  const currentRecords = inputValue ? filterRows : allRecords; // Use filtered records if searching
  const npage = Math.ceil(currentRecords.length / recordsPage);
  const lastIndex = currentPage * recordsPage;
  const firstIndex = lastIndex - recordsPage;
  const records = currentRecords.slice(firstIndex, lastIndex); // Apply pagination on filtered or full records
  const number = [...Array(npage + 1).keys()].slice(1);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      setRowNumber(10 * (currentPage - 1) - 9);
    }
  }

  function changeCPage(id) {
    setRowNumber(10 * id - 9);
    setCurrentPage(id);
  }

  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
      setRowNumber(10 * (currentPage + 1) - 9);
    }
  }

  return (
    <div className="card p-2" style={{borderRadius: "20px"}}>
      <Col lg={12}>
        <Card>
          <Card.Header>
            <h3>Manager Reports</h3>
            <div className="input-group search-area mb-md-0 mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search here..."
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
              <span className="input-group-text">
                <Link to={"#"}>
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5605 15.4395L13.7527 11.6317C14.5395 10.446 15 9.02625 15 7.5C15 3.3645 11.6355 0 7.5 0C3.3645 0 0 3.3645 0 7.5C0 11.6355 3.3645 15 7.5 15C9.02625 15 10.446 14.5395 11.6317 13.7527L15.4395 17.5605C16.0245 18.1462 16.9755 18.1462 17.5605 17.5605C18.1462 16.9747 18.1462 16.0252 17.5605 15.4395V15.4395ZM2.25 7.5C2.25 4.605 4.605 2.25 7.5 2.25C10.395 2.25 12.75 4.605 12.75 7.5C12.75 10.395 10.395 12.75 7.5 12.75C4.605 12.75 2.25 10.395 2.25 7.5V7.5Z"
                      fill="#01A3FF"
                    />
                  </svg>
                </Link>
              </span>
            </div>
          </Card.Header>
          <Card.Body>
            <div className="col-sm-12 wow fadeInUp" data-wow-delay="1.5s">
              <div className="table-responsive full-data">
                <div
                  id="example-student_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <Table responsive>
                    <thead>
                      <tr
                        style={{ background: "#212A50", textAlign: "center" }}
                      >
                        <th className="width80">
                          <strong>Sr.No</strong>
                        </th>
                        <th className="width80">
                          <strong>Manager ID</strong>
                        </th>
                        <th>
                          <strong> Name</strong>
                        </th>
                        <th>
                          <strong>No of Individuals</strong>
                        </th>
                        <th>
                          <strong>No of Courses</strong>
                        </th>
                        <th>
                          <strong>No of bundles</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {records &&
                        records.map((item, index) => (
                          <tr style={{ textAlign: "center" }}>
                            <td>
                              <strong>{rowNumber + index}</strong>
                            </td>
                            <td>
                              <strong>{item.id}</strong>
                            </td>
                            <td>{item?.first_name + " " + item?.last_name}</td>
                            <td>{item?.individuals_count}</td>
                            <td>
                              {item?.assigned_bundle_count +
                                item?.purchased_bundle_count}
                            </td>
                            <td>
                              {item?.assigned_course_count +
                                item?.purchased_course_count}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  {/* Pagination Start */}
                  <div className="d-sm-flex text-center justify-content-end align-items-center">
                    <div className="dataTables_paginate paging_simple_numbers justify-content-center">
                      <Link
                        className="paginate_button previous disabled"
                        to="#"
                        onClick={prePage}
                      >
                        <i className="fa-solid fa-angle-left" />
                      </Link>
                      <span>
                        {number.map((n, i) => (
                          <Link
                            className={`paginate_button ${
                              currentPage === n ? "current" : ""
                            }`}
                            key={i}
                            onClick={() => changeCPage(n)}
                          >
                            {n}
                          </Link>
                        ))}
                      </span>
                      <Link
                        className="paginate_button next"
                        to="#"
                        onClick={nextPage}
                      >
                        <i className="fa-solid fa-angle-right" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Pagination End */}
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};

export default CourseManReport;
